<template>
  <div>
    <section>
      <h2>
        {{ title }}
      </h2>
      <h3 v-if="subTitle">
        {{ subTitle }}
      </h3>
      <div
        v-if="description"
        class="description"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="description" />
      </div>
      <slot
        class="description"
        name="description"
      />
    </section>
    <v-divider
      v-show="underline"
      class="mb-0"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    underline: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
section {
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
section > h2 {
  flex: auto;
  padding-bottom: 15px;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
}
section > h3 {
  margin-left: 10px;
  padding-bottom: 15px;
}
.description {
  padding-bottom: 15px;
  flex: 1;
  text-align: right;
}
</style>
